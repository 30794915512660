import { TOGGLE, USER_LOGIN } from "./Type";
import { _isEmpty } from "../tools/util";
export const toggleSpinner = (dispatch) => (payload = null) => {
  dispatch({
    type: TOGGLE,
    payload: payload,
  });
};
export const checkLogin = (dispatch) => () => {
  const token = localStorage.getItem("token");
  const userInfo = localStorage.getItem("userInfo");
  const expire = localStorage.getItem("exp");
  const payload = { sess: token, exp: expire, userInfo: JSON.parse(userInfo) };
  if (!_isEmpty(token)) {
    if (Number(new Date()) < parseInt(expire))
      userLogin(dispatch)(payload);
    else userLogout(dispatch)(payload);
  } else {
    userLogout(dispatch)(payload);
  }
};

export const userLogin = (dispatch) => (payload = {}) => {
  localStorage.setItem("exp", payload.exp);
  localStorage.setItem("token", payload.sess);
  localStorage.setItem("userInfo", JSON.stringify(payload.userInfo));
  dispatch({
    type: USER_LOGIN,
    payload: { userInfo: payload.userInfo, token: payload.sess },
  });
  // window.location.assign("#/")
};

export const userLogout = (dispatch) => (payload = {}) => {
  localStorage.removeItem("token");
  localStorage.removeItem("userInfo");
  // localStorage.removeItem("exp");
  window.location.assign("#/login");
};
