/* eslint-disable no-useless-escape */
import moment from "moment";
export const _isEmpty = (data) => {
  const queryDefault =
    data === null || data === "undefined" || data === undefined || data === "";
  if (queryDefault) return true;
  if (typeof data === "number") return false;
  if (typeof data === "string") return false;
  const obj = queryDefault ? [] : data;
  return Object.entries(obj).length === 0;
};

export const _trim = (x) => {
  return x ? x.replace(/^\s+|\s+$/gm, "") : null;
};

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const _appendFormData = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    try {
      Array.isArray(data[key])
        ? data[key].forEach((value) => formData.append(key + "[]", value))
        : formData.append(key, data[key]);
    } catch (error) {
      console.log("Error Form : ", error);
    }
  });
  return formData;
};

export const _appendFields = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    Array.isArray(data[key])
      ? data[key].forEach((value) => {
        Object.keys(value).forEach((keyF) => {
          let keyName = keyF !== "file" ? key + keyF + "[]" : key + keyF;
          formData.append(keyName, value[keyF]);
        });
      })
      : formData.append(key, data[key]);
  });
  return formData;
};

export const _randomID = (prefix, lavle = null) => {
  let newid1 = Math.random()
    .toString(36)
    .replace("0.", prefix || "");
  let newid2 = Math.random().toString(36).replace("0.", "-");
  return lavle ? newid1 : newid1 + newid2;
};
export const defultFunction = (res) => {
  return;
};

export const _filterObjKeyHasValue = (value) => {
  let myKeys = Object.keys(value).filter((key) => !_isEmpty(value[key]));
  let result = myKeys.reduce((obj, key) => {
    obj[key] = value[key];
    return obj;
  }, {});
  return result;
};

export const _removeKeyObj = (objData, notAllowed) => {
  const filtered = Object.keys(objData)
    .filter((key) => !notAllowed.includes(key))
    .reduce((obj, key) => {
      obj[key] = objData[key];
      return obj;
    }, {});
  return filtered;
};

export const dateTimeFormat = (numberDateTime) => {
  const result = moment(+numberDateTime).format("HH:mm:ss DD/MM/YYYY");
  return result;
};

export const dateFormat = (numberDateTime) => {
  const result = moment(+numberDateTime).format("DD/MM/YYYY");
  return result;
};

export const timeFormat = (numberDateTime) => {
  const result = moment(+numberDateTime).format("HH:mm:ss");
  return result;
};

export const findTotalPaginationPage = (res) => {
  const countTotal = Math.ceil(res.total / res.perpage);
  if (countTotal === 0) {
    return 1;
  } else return countTotal;
};

// export const findTotalPagi = (limit) => {
//   const countTotal = Math.ceil(res.total / res.perpage);
//   if (countTotal === 0) {
//     return 1;
//   } else return countTotal;
// };



export const NumberRegX = (language = "") => {
  const number = RegExp(/[ก-๙A-Za-z_\-\*\&\%\$\#\!\+\{\}\^\[\];]/);
  const regex = { number };
  return regex[language.toLowerCase()];
};

export const ChangeToK = (num) => {
  return Math.abs(num) > 999999 ? Math.sign(num) * ((Math.abs(num) / 1000000).toFixed(1)) + 'm' :
    Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' :
      Math.sign(num) * Math.abs(num)
}

export const toDayUnix = () => {
  const toDayStart = moment().startOf('day').unix() * 1000;
  const endDayEnd = moment().endOf('day').unix() * 1000;
  return [toDayStart, endDayEnd]
}

export const spliteToShortBookingNo = (bookingNo) => {
  try {
    const split = bookingNo.split('-')
    if (split.length > 2) {
      const result = split[1] + '-' + split[2].slice(-7);
      return result;
    } else return bookingNo
  } catch (error) {
    return bookingNo;
  }
}

const PRIORITY = {
  1: "เร่งด่วนที่สุด",
  5: "เร่งด่วน",
  10: "ปกติ"
}
export const getPriorityString = (priority) => {
  if (![1, 5, 10].includes(parseInt(priority))) return "";
  return PRIORITY[priority];
}

const BOOKING_STATUS = {
  working: "กำลังปฏิบัติงาน",
  done: "ปิดงานแล้ว",
  accept: "รับงานแล้ว",
  pending: "รอดำเนินการ",
  cancel: "ยกเลิก",
  unavailable: "ไม่พร้อมรับงาน"
}

export const getBookingStatusString = (status) => {
  if (!['working', 'done', 'accept', 'pending', 'cancel', 'unavailable'].includes(status)) return ""
  return BOOKING_STATUS[status]
}