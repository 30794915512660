import React, { useReducer, useContext, createContext } from "react";
import Reducer from "./Reducer";
import { toggleSpinner, checkLogin, userLogin, userLogout } from "./Action";

const StateContext = createContext();
const DispatchContext = createContext();

const initialValue = {
  loadingSpin: false,
  token: "",
  userInfo: {
    image: "/images/no-img/user.png",
    displayname: "No data.",
  },
};

const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialValue);
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider
        value={{
          dispatch,
          checkLogin: checkLogin(dispatch),
          userLogout: userLogout(dispatch),
          userLogin: userLogin(dispatch),
          toggleSpinner: toggleSpinner(dispatch),
        }}
      >
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};

const useContextState = () => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error("useSpinState must be used within a SpinProvider");
  }
  return context;
};

const useContextDispatch = () => {
  const context = useContext(DispatchContext);
  if (context === undefined) {
    throw new Error("useSpinDispatch must be used within a SpinProvider");
  }
  return context;
};

export { ContextProvider, useContextState, useContextDispatch };
