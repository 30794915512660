import { TOGGLE, USER_LOGIN } from "./Type";

export default (state, action) => {
  switch (action.type) {
    case TOGGLE:
      return {
        ...state,
        loadingSpin: action.payload ? action.payload : !state.loadingSpin,
      };
    case USER_LOGIN:
      return {
        ...state,
        token: action.payload.token,
        userInfo: action.payload.userInfo,
      };
    default:
      return state;
  }
};
